<template>
    <div class="contain" v-infinite-scroll="load" :infinite-scroll-disabled="finish">
        <searchBar @search="search"></searchBar>
        <div class="title bgf flex">
            <div class="fs_16 color_333 flex_start">
                <span>已发布职位</span>
                <span class="badge">{{ total || 0 }}</span>
            </div>
            <div @click="authPath([], postJob)" class="btn flex_cen">
                <img src="@/assets/imgs/icon/post-icon.png" class="post-icon" alt="" />
                <span class="fs_14 color">发布职位</span>
            </div>
        </div>
        <div class="list" v-if="list.length!=0">
            <div class="item bgf mt_10" v-for="(item, index) in list" :key="index">
                <div class="flex padding">
                    <div class="flex_c item_title">
                        <el-popover placement="bottom" title="" :width="180" trigger="manual" :visible="item.qrcodeVisible">
                            <template #reference>
                                <div class="fs_16 bold color_333 one mr_10 proview pointer" style="max-width:470px" @mouseenter="proview(item)" @mouseleave="item.qrcodeVisible=false">
                                    {{ item.job_name }}
                                    <template v-if="item.city">
                                        [{{ item.city }}{{ item.area ? "-" + item.area : "" }}]
                                    </template>
                                </div>
                                <!-- <p class="fs_14 color btn proview" @mouseenter="proview(item)" @mouseleave="item.qrcodeVisible=false">预览职位</p> -->
                            </template>
                            <div class="qrcode-main">
                                <div :ref="'jobQRcode'+item.id" :id="'jobQRcode'+item.id"></div>
                                <p class="fs_14 center color_333 mt_5">微信扫一扫</p>
                                <img src="../../assets/imgs/layout/zhilu.png" class="logo-thumb" alt="">
                            </div>
                        </el-popover>
                        <router-link :to="`/position/post?id=${item.id}`" class="edit-btn fs_14 center color_666">
                            <i class="el-icon-edit-outline"></i>
                            编辑
                        </router-link>
                    </div>
                    <div class="flex_1 flex center_body">
                        <router-link :to="
								`/position/collection/${item.id}/${item.job_name} [${item.city}-${item.area}]`
							">
                            <div class="fs_12 wait flex_cen ml_20 btn">
                                <span class="color fs_20 mr_5">{{ item.apply_num }}人</span>
                                <span class="sub_color">已投递</span>
                            </div>
                            <!-- <span class="info_color mr_5">
								{{ item.apply_num }}人已投递
							</span>
							<span class="el-icon-arrow-right info_color"></span> -->
                        </router-link>
                        <router-link :to="
								`/position/invite/${item.id}/${item.job_name} [${item.city}-${item.area}]`
							">
                            <div class="fs_12 wait flex_cen ml_20 btn">
                                <span class="sub_color mr_5">已邀请</span>
                                <span class="color fs_20 mr_5">{{ item.interview_num }}人</span>
                                <span class="sub_color">面试</span>
                            </div>
                            <!-- <span class="info_color mr_5">
								已邀请{{ item.interview_num }}人面试
							</span>
							<span class="el-icon-arrow-right info_color"></span> -->
                        </router-link>
                        <router-link :to="
								`/position/entry/${item.id}/${item.job_name} [${item.city}-${item.area}]`
							">
                            <div class="fs_12 wait flex_cen ml_20 btn">
                                <span class="color fs_20 mr_5">{{ item.join_num }}人</span>
                                <span class="sub_color">已入职</span>
                            </div>
                            <!-- <span class="info_color mr_5">{{ item.join_num }}人已入职</span>
							<span class="el-icon-arrow-right info_color"></span> -->
                        </router-link>
                    </div>
                    <div class="flex_c">
                        <el-switch v-model="item.status" active-color="#1890FF" inactive-color="#D1DEE9" :inactive-text="item.status == 0 ? '停止招聘' : ''" :active-text="item.status == 1 ? '正在热招' : ''" :active-value="1" :inactive-value="0" class="btn" @change="statusChange(item.id, item.status, index)">
                        </el-switch>
                        <!-- <router-link :to="`/position/post?id=${item.id}`" class="fs_14 sub_color flex_1">
                            编辑
                        </router-link> -->
                    </div>
                </div>
                <div class="flex require top_link fs_14 color_999">
                    <p class="use_tip">
                        <span class="line">{{ item.dept_name }}</span>
                        <span class="line">招聘{{ item.num }}人</span>
                        <span class="line">{{ item.salary_min }}-{{ item.salary_max }}元</span>
                        <span class="line" v-if="item.city">{{ item.city }}{{ item.area }}</span>
                        <span class="line">{{ item.work_exp_text }}</span>
                        <span class="line">{{ item.gender_text }}</span>
                        <span class="line">{{ item.age_text }}</span>
                        <span class="line">{{ item.education_text }}</span>
                    </p>
                    <p>
                        <span v-if="item.creator_name">
                            {{ item.creator_name }}
                        </span>
                        &nbsp;
                        <span v-if="item.create_time_text">
                            {{ item.create_time_text }}
                        </span>
                        <span>发布</span>
                    </p>
                </div>
            </div>
        </div>

        <empty :text="'暂无职位列表'" v-else :finish="finish" :length="list.length"></empty>

        <el-backtop :bottom="100"></el-backtop>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
	name: "Position",
	data() {
		return {
			keywords: "",
			list: [],
			page: 1,
			limit: 10,
			finish: false,
			total: 0,
			switchs: false,
			qrcodeVisible: false
		}
	},
	created() {
		this.getList()
	},
	methods: {
		load() {
			this.page++
			this.getList()
		},
		getList() {
			this.$axios({
				url: `/ent/v3/job`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					keywords: this.keywords
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					res.data.list.forEach(item => {
						item.qrcodeVisible = false
					})
					this.list = this.list.concat(res.data.list)
					this.finish = this.list.length >= res.data.total
					this.total = res.data.total
				} else {
					this.finish = true
				}
			})
		},
		statusChange(id, status, index) {
			this.$axios({
				url: `/ent/v3/job/status/${id}`,
				method: "PUT",
				data: {
					status
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list[index].status = status
				} else {
					this.list[index].status = Number(!status)
				}
			})
		},

		search(v) {
			this.keywords = v
			this.page = 1
			this.list = []
			this.finish = false
			this.getList()
		},
		postJob() {
			this.$router.push("/position/post")
		},
		proview(item) {
			document.getElementById("jobQRcode" + item.id).innerHTML = ""
			this.$nextTick(() => {
				new QRCode(this.$refs['jobQRcode' + item.id], {
					text: `https://api.zhiluinfo.com/workDetail?id=${item.id}`,
					width: 200,
					height: 200,
					colorDark: "#000000", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
				})
				item.qrcodeVisible = true
				this.$forceUpdate()
			})
		}
	}
}
</script>

<style lang="less" scoped>
.center_body {
    padding-right: 50px;
}
.item_title {
    width: 550px;
    height: 28px;
}
.title {
    padding: 20px 40px 20px 54px;
    border-radius: 2px;
    .post-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
}
.qrcode-main {
    position: relative;
    .logo-thumb {
        position: absolute;
        width: 36px;
        height: 36px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -84%);
        z-index: 999;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
}
/deep/.list {
    .item {
        border-radius: 2px;
        &:hover {
            .edit-btn {
                display: block;
            }
        }

        .el-switch__core {
            width: 110px !important;
            height: 36px;
            border-radius: 36px;
        }
        .el-switch__core .el-switch__action {
            width: 32px;
            height: 32px;
        }
        .el-switch.is-checked .el-switch__core .el-switch__action {
            margin-left: -33px;
        }
        .el-switch__label--left {
            left: 40px;
            position: absolute;
            z-index: 4;
            color: #fff;
        }
        .el-switch__label--right {
            // left: -98px;
            position: absolute;
            z-index: 4;
            color: #fff;
        }
        .require {
            padding: 20px 40px;
        }
        .proview {
            text-decoration: underline;
        }
    }
    .edit-btn {
        min-width: 76px;
        height: 28px;
        padding: 0 15px;
        line-height: 28px;
        border: 1px solid #ccc;
        border-radius: 2px;
        margin-left: 20px;
        transition: all 0.2s ease;
        display: none;
        position: static;
        &:hover {
            color: #fff;
            background: #1890ff;
            border-color: #1890ff;
            transition: all 0.2s ease;
        }
    }
}
</style>
